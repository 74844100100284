import React from 'react'
import logo from '../assets/logo.png'
import { CiMenuBurger, CiSearch } from "react-icons/ci";
import {Button, Input, Navbar, NavbarBrand} from "@nextui-org/react";
import { Link, useLocation } from 'react-router-dom'
import {Accordion, AccordionItem} from "@nextui-org/react";

const Header = () => {
  const location = useLocation()
  return (
    <Navbar className='bg-[#0D1548]' shouldHideOnScroll>
      <NavbarBrand>
        <img src={logo} alt='' className='h-[50px] object-cover' />
      </NavbarBrand>
    </Navbar>
  )
}

export default Header


// <div className={
//   location.pathname.startsWith('/user')?
//   'h-fit w-full py-[15px] backdrop-blur top-0 z-50 bg-blue-500/5 hidden':
//   'h-fit w-full py-[15px] backdrop-blur fixed top-0 z-50 bg-blue-500/5'
// }>
//   <section className='h-fit w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px]'>
    
//     <div className='h-fit w-full bg-white rounded-[8px] flex items-center justify-between pr-[15px] md:px-[25px] md:hidden'>
//       <Accordion>
//         <AccordionItem key="anchor" aria-label="Anchor" indicator={<CiMenuBurger className='text-xl' />} title={
//           <div className='h-[50px] flex items-center justify-between'>
            
//             <Link to='/' className='flex items-center justify-start'>
//               <Button variant='' className='h-[50px] w-[40px]'>
//               <img src={logo} alt='' className='h-[50px] w-[50px] object-contain' />
//               </Button>
//               <p className='text-xs leading-4'>Skills Sphere <br/> Solutions</p>
//             </Link>
//             <div className='flex items-center gap-2'>
//               <Link to={'/register'}>
//                 <Button color='primary' className='rounded-[4px] capitalize' variant='solid'>
//                   Register
//                 </Button>
//               </Link>  
//             </div>
//           </div>
//         }>
//           <div className='flex flex-col divide-y-1 divide-black/10 my-2 ml-[15px]'>
//             <Link to={'/contact'} className='py-3 px-6 text-sm' >Contact us</Link>
//             <Link to={'/about'} className='py-3 px-6 text-sm' >About us</Link>
//             <Link to={'/login'} className='py-3 px-6 text-sm' >Login</Link>
//           </div>
//         </AccordionItem>
//       </Accordion>
      
//     </div>

//     <div className='h-fit w-full bg-white rounded-[8px] md:flex items-center justify-between px-[15px] md:px-[25px] hidden py-2'>
//       <Link to={'/'} className='h-[40px] flex items-center justify-between gap-3'>
//         <img src={logo} alt='' className='h-[40px] w-fit object-contain' />
//         <div className='h-full w-[1px] bg-black opacity-10'></div>
//         <p className='uppercase text-[11px] font-bold tracking-wide leading-[16px]'>Skill Sphere <br/>Solutions</p> 
//       </Link>
//       <div className='flex flex-row gap-6'>
//         <Link to='/contact' className='py-3 md:text-xs capitalize font-medium opacity-50 hover:opacity-100 duration-200' >Contact us</Link>
//         <Link to='/about' className='py-3 md:text-xs capitalize font-medium opacity-50 hover:opacity-100 duration-200' >About us</Link>
//         <Link to='/login' className='py-3 md:text-xs capitalize font-medium opacity-50 hover:opacity-100 duration-200' >Login</Link>
        
//       </div> 
//       <div className='flex items-center gap-2'>
//         <Input size={'sm'} type="email" className='text-sm' placeholder='Search' startContent={
//         <CiSearch className="text-2xl text-default-400 pointer-events-none flex-shrink-0 " />
//       }/>
//         <Link to={'/register'}>
//           <Button color='primary' className='rounded-[4px] text-sm md:text-xs capitalize font-medium' size='sm' variant='solid'>
//             Register
//           </Button> 
//         </Link> 
//       </div>         
//     </div>

    
//   </section>
// </div>