import React, { useEffect, useState } from 'react'
import { fetchUserDetails } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Input, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';

const UserInformationScreen = () => {
  const {userId} = useParams()
  const [password, setPassword] = useState('');
  const location = useLocation()


  const dispatch = useDispatch();

  const { userDetails, loading, error } = useSelector((state) => state.user); // Updated selector

  const submitHandler = (e) => {
    e.preventDefault();
    if (userId){
      dispatch(fetchUserDetails(userId, password));
    }
  };
  useEffect(() => {
    window.scroll(0,0);
  }, [location]);

  return (
    <div className='h-fit w-full'>
      <div className='h-fit min-h-screen w-full max-w-[1024px] mx-auto px-[15px] sm:px-[25px] md:px-[50px] flex items-start justify-start py-4 md:py-8'>
        {
          userDetails?
          <div className=''>
              <div className='flex flex-col gap-4 '>
                <p className='text-2xl font-bold uppercase'>Account / {userId}</p>
                <div className='flex flex-col gap-1'>
                  <p className='text-sm capitalize opacity-75'>WELCOME GUEST!</p>
                  <p className='text-sm capitalize opacity-50'>You are given access to partial account information of</p>
                  <div className='bg-blue-600 px-6 py-5 flex flex-col text-white'>
                    <p className='text-sm font-semibold'>{userDetails.username}</p>
                    <p className='text-sm font-semibold'>A/C Number: {userDetails.saving_account_number}</p>
                  </div>
                </div>
                <div className='flex flex-col gap-2 pb-4'>
                  <p className='text-2xl font-bold uppercase'>Private & Confidential</p>
                </div>
              </div>
              {userDetails && (

              <div>
                <Table radius='sm' hideHeader  aria-label="Example static collection table" className='bg-transparent '>
                  <TableHeader>
                    <TableColumn>NAME</TableColumn>
                    <TableColumn>ROLE</TableColumn>
                  </TableHeader>
                  <TableBody>
                    <TableRow key="1">
                      <TableCell className='text-opacity-50'>Address: </TableCell>
                      <TableCell className='font-medium'>{userDetails.address}</TableCell>
                    </TableRow>
                    <TableRow key="2">
                      <TableCell className='text-opacity-50'>NIC Number: </TableCell>
                      <TableCell className='font-medium'>{userDetails.nic_number}</TableCell>
                    </TableRow>
                    <TableRow key="3">
                      <TableCell className='text-opacity-50'>Savings Account Number: </TableCell>
                      <TableCell className='font-medium'>{userDetails.saving_account_number}</TableCell>
                    </TableRow>
                    <TableRow key="4">
                      <TableCell className='text-opacity-50'>Date of Open Balance: </TableCell>
                      <TableCell className='font-medium'>{userDetails.date_of_open_balance}</TableCell>
                    </TableRow>
                    <TableRow key="5">
                      <TableCell>Maturity date: {userDetails.balance_updated_time} </TableCell>
                      <TableCell>{userDetails.date_of_open_balance}</TableCell>
                    </TableRow>
                    <TableRow key="4">
                      <TableCell className='text-opacity-50'>Balance Amount (LKR): </TableCell>
                      <TableCell className='font-medium'>{userDetails.balance_amount}</TableCell>
                    </TableRow>
                    <TableRow key="5">
                      <TableCell className='text-opacity-50'>Balance Amount (in words LKR): </TableCell>
                      <TableCell className='font-medium'>{userDetails.balance_amount_in_words}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
               
              </div>
            )}
          </div>:
          <div className=''>
          <div className='flex flex-col gap-4'>
            <p className='text-2xl font-bold uppercase'>Account / {userId}</p>
            <div className='flex flex-col gap-2'>
              <p className='text-sm capitalize opacity-75'>Statement Verification</p>
              <p className='text-sm capitalize opacity-50'>To view this protected Statement, enter the passcode below:</p>
            </div>
          </div>
          <form onSubmit={submitHandler}>
              <div className='py-4'>
                <Input
                size='sm'
                
                  type="password"
                  label="Password"
                  variant='bordered'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  isInvalid={error?true:false}
                  errorMessage={error ? error:''}
                  radius='sm'
                />
              </div>
              <Button radius='sm' color='primary' type="submit" disabled={loading} startContent={
                loading? <Spinner/>:
                ''
              }>
                {loading ? 'Loading...' : 'Enter'}
              </Button>
            </form>
          
        </div>
        }
      </div>
    </div>
  )
}

export default UserInformationScreen