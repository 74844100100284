import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Button } from '@nextui-org/react';

const HomeScreen = () => {
  return (
    <body class="bg-gray-100 text-gray-700 font-sans leading-normal">
  <div id="Wrapper" class="relative z-0 w-full bg-white">
    <div id="Content" role="main">
      <div class="content_wrapper mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <main class="sections_group">

          <section class="py-12 bg-gray-50">
            <div class="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <img src="https://sdsverification.com/wp-content/uploads/2020/12/finance4-features-pic1.png" alt="Finance Features Pic 1" class="rounded-lg shadow-md"/>
              </div>
              <div>
                <h6 class="text-green-500 font-bold text-sm uppercase tracking-wide">OUR FIRM</h6>
                <h3 class="text-2xl font-bold text-gray-800 mt-2">We are agile. Constantly focused on growth and being better</h3>
                <p class="mt-4 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit.
                </p>
                <a href="https://sdsverification.com/our-firm/" class="mt-4 inline-block bg-blue-600 text-white font-medium py-2 px-4 rounded shadow hover:bg-blue-700">
                  Read about us
                </a>
              </div>
            </div>
          </section>

          <section class="py-12 bg-white">
            <div class="text-center">
              <h6 class="text-green-500 font-bold text-sm uppercase tracking-wide">
                <img src="https://sdsverification.com/wp-content/uploads/2020/12/finance4-slider-icon1.png" alt="Icon" class="inline w-6 h-6"/>
                WHY TO CHOOSE US
              </h6>
              <h3 class="text-2xl font-bold text-gray-800 mt-2">Stay on top of your income and expenses</h3>
              <p class="mt-4 text-gray-600">It’s essential to plan and tightly manage <span class="text-green-500">Your financial performance</span>.</p>
            </div>
          </section>

          <section class="py-12 bg-gray-50">
            <div class="grid md:grid-cols-3 gap-6">
              <div class="text-center">
                <img src="https://sdsverification.com/wp-content/uploads/2020/12/finance4-home-icon1.png" alt="Icon 1" class="mx-auto w-20 h-20"/>
                <h6 class="text-green-500 font-bold text-sm mt-4">#1</h6>
                <h4 class="text-xl font-bold text-gray-800 mt-2">Adipiscing elit sed do eiusmod tempor</h4>
              </div>
              <div class="text-center">
                <img src="https://sdsverification.com/wp-content/uploads/2020/12/finance4-home-icon2.png" alt="Icon 2" class="mx-auto w-20 h-20"/>
                <h6 class="text-green-500 font-bold text-sm mt-4">#2</h6>
                <h4 class="text-xl font-bold text-gray-800 mt-2">Fusce ut velit laoreet</h4>
              </div>
              <div class="text-center">
                <img src="https://sdsverification.com/wp-content/uploads/2020/12/finance4-home-icon3.png" alt="Icon 3" class="mx-auto w-20 h-20"/>
                <h6 class="text-green-500 font-bold text-sm mt-4">#3</h6>
                <h4 class="text-xl font-bold text-gray-800 mt-2">Phasellus venenatis leo eu semper varius</h4>
              </div>
            </div>
          </section>

          <section class="py-12 bg-white">
            <div class="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <h6 class="text-green-500 font-bold text-sm uppercase tracking-wide">START NOW</h6>
                <h3 class="text-2xl font-bold text-gray-800 mt-2">Make your finances clear and organized</h3>
                <p class="mt-4 text-gray-600">Curabitur sed iaculis dolor, non congue ligula. Maecenas imperdiet ante eget hendrerit posuere.</p>
                <a href="https://sdsverification.com/features/" class="mt-4 inline-block bg-blue-600 text-white font-medium py-2 px-4 rounded shadow hover:bg-blue-700">
                  Check all features
                </a>
              </div>
              <div>
                <img src="https://sdsverification.com/wp-content/uploads/2020/12/finance4-how-slider-pic2.png" alt="Feature Slider Pic 2" class="rounded-lg shadow-md"/>
              </div>
            </div>
          </section>

          
        </main>
      </div>
    </div>
  </div>
</body>
  )
}

export default HomeScreen



// <div className='h-fit w-full text-black py-[120px] md:pt-[100px] flex flex-col gap-8 overflow-hidden'>
//       <section className='h-fit w-full'>
//         <Swiper
//           slidesPerView={1}
//           pagination={{
//             dynamicBullets: true,
//           }}
//           autoplay={{
//             delay: 5000,
//             disableOnInteraction: false,
//           }}
//           modules={[ Pagination, Autoplay ]}
//           className="h-fit md:h-[450px] "
//         >
//           <SwiperSlide className='h-full w-full bg-transparent flex items-center justify-center pb-8'>
//             <div className='h-[92%] w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] '>
//               <div className='h-full w-full bg-white rounded-[8px] grid grid-cols-1 md:grid-cols-2 md:items-center md:justify-between'>
//                 <div className='h-[300px] md:h-fit flex flex-col gap-6 md:gap-8 py-8 px-8 md:px-16'>
//                   <div className='flex flex-col gap-2 md:gap-4'>
//                     <p className='text-3xl md:text-5xl font-medium'>Take Your Business to the Next Level</p>
//                     <p className='text-xs md:text-sm opacity-50 text-justify'>We provide innovative websites, mobile apps, and desktop applications to help businesses start strong and grow smarter.</p>
//                   </div>
//                   <div className='flex gap-2'>
//                     <Button className='rounded-[4px]' color='primary'> Contact us</Button>
//                     <Button className='rounded-[4px]' color='primary' variant='bordered'> Whatsapp</Button>
//                   </div>
//                 </div>
//                 <div className='h-[300px] md:h-full w-full md:w-fit md:mx-auto'>
//                   <img src='https://png.pngtree.com/png-vector/20230902/ourmid/pngtree-teenagers-distance-learning-online-education-3d-character-illustration-png-image_9239403.png' alt='' className='h-full w-full object-cover' />
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>

//           <SwiperSlide className='h-full w-full bg-transparent flex items-center justify-center pb-8'>
//             <div className='h-[92%] w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] '>
//               <div className='h-full w-full bg-white rounded-[8px] grid grid-cols-1 md:grid-cols-2 md:items-center md:justify-between'>
//                 <div className='h-[300px] md:h-fit flex flex-col gap-6 md:gap-8 py-8 px-8 md:px-16'>
//                   <div className='flex flex-col gap-2 md:gap-4'>
//                     <p className='text-3xl md:text-5xl font-medium'>Master the Skills of Tomorrow</p>
//                     <p className='text-xs md:text-sm opacity-50 text-justify'>Dive into hands-on courses in Web Development, Mobile Apps, UI/UX Design, and Ethical Hacking. Learn the latest industry techniques and get ahead in your career.</p>
//                   </div>
//                   <div className='flex gap-2'>
//                     <Button className='rounded-[4px]' color='primary'> Contact us</Button>
//                     <Button className='rounded-[4px]' color='primary' variant='bordered'> Whatsapp</Button>
//                   </div>
//                 </div>
//                 <div className='h-[300px] md:h-full w-full md:w-fit md:mx-auto'>
//                   <img src='https://png.pngtree.com/png-vector/20230902/ourmid/pngtree-teenagers-distance-learning-online-education-3d-character-illustration-png-image_9239403.png' alt='' className='h-full w-full object-cover' />
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>

//           <SwiperSlide className='h-full w-full bg-transparent flex items-center justify-center pb-8'>
//             <div className='h-[92%] w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] '>
//               <div className='h-full w-full bg-white rounded-[8px] grid grid-cols-1 md:grid-cols-2 md:items-center md:justify-between'>
//                 <div className='h-[300px] md:h-fit flex flex-col gap-6 md:gap-8 py-8 px-8 md:px-16'>
//                   <div className='flex flex-col gap-2 md:gap-4'>
//                     <p className='text-3xl md:text-5xl font-medium'>Flexible Learning, Real Results</p>
//                     <p className='text-xs md:text-sm opacity-50 text-justify'>Enjoy the freedom to learn at your own pace with live sessions, recordings, and 24/7 support. Build real-world projects that showcase your skills.</p>
//                   </div>
//                   <div className='flex gap-2'>
//                     <Button className='rounded-[4px]' color='primary'> Contact us</Button>
//                     <Button className='rounded-[4px]' color='primary' variant='bordered'> Whatsapp</Button>
//                   </div>
//                 </div>
//                 <div className='h-[300px] md:h-full w-full md:w-fit md:mx-auto'>
//                   <img src='https://png.pngtree.com/png-vector/20230902/ourmid/pngtree-teenagers-distance-learning-online-education-3d-character-illustration-png-image_9239403.png' alt='' className='h-full w-full object-cover' />
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>

//           <SwiperSlide className='h-full w-full bg-transparent flex items-center justify-center pb-8'>
//             <div className='h-[92%] w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] '>
//               <div className='h-full w-full bg-white rounded-[8px] grid grid-cols-1 md:grid-cols-2 md:items-center md:justify-between'>
//                 <div className='h-[300px] md:h-fit flex flex-col gap-6 md:gap-8 py-8 px-8 md:px-16'>
//                   <div className='flex flex-col gap-2 md:gap-4'>
//                     <p className='text-3xl md:text-5xl font-medium'>Expert-Led Courses for Every Level</p>
//                     <p className='text-xs md:text-sm opacity-50 text-justify'>Whether you're starting fresh or sharpening advanced skills, our expert instructors guide you through practical lessons designed for all skill levels.</p>
//                   </div>
//                   <div className='flex gap-2'>
//                     <Button className='rounded-[4px]' color='primary'> Contact us</Button>
//                     <Button className='rounded-[4px]' color='primary' variant='bordered'> Whatsapp</Button>
//                   </div>
//                 </div>
//                 <div className='h-[300px] md:h-full w-full md:w-fit md:mx-auto'>
//                   <img src='https://png.pngtree.com/png-vector/20230902/ourmid/pngtree-teenagers-distance-learning-online-education-3d-character-illustration-png-image_9239403.png' alt='' className='h-full w-full object-cover' />
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>

//           <SwiperSlide className='h-full w-full bg-transparent flex items-center justify-center pb-8'>
//             <div className='h-[92%] w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] '>
//               <div className='h-full w-full bg-white rounded-[8px] grid grid-cols-1 md:grid-cols-2 md:items-center md:justify-between'>
//                 <div className='h-[300px] md:h-fit flex flex-col gap-6 md:gap-8 py-8 px-8 md:px-16'>
//                   <div className='flex flex-col gap-2 md:gap-4'>
//                     <p className='text-3xl md:text-5xl font-medium'>Expert-Led Courses for Every Level</p>
//                     <p className='text-xs md:text-sm opacity-50 text-justify'>Whether you're starting fresh or sharpening advanced skills, our expert instructors guide you through practical lessons designed for all skill levels.</p>
//                   </div>
//                   <div className='flex gap-2'>
//                     <Button className='rounded-[4px]' color='primary'> Contact us</Button>
//                     <Button className='rounded-[4px]' color='primary' variant='bordered'> Whatsapp</Button>
//                   </div>
//                 </div>
//                 <div className='h-[300px] md:h-full w-full md:w-fit md:mx-auto'>
//                   <img src='https://png.pngtree.com/png-vector/20230902/ourmid/pngtree-teenagers-distance-learning-online-education-3d-character-illustration-png-image_9239403.png' alt='' className='h-full w-full object-cover' />
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>

//           <SwiperSlide className='h-full w-full bg-transparent flex items-center justify-center pb-8'>
//             <div className='h-[92%] w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] '>
//               <div className='h-full w-full bg-white rounded-[8px] grid grid-cols-1 md:grid-cols-2 md:items-center md:justify-between'>
//                 <div className='h-[300px] md:h-fit flex flex-col gap-6 md:gap-8 py-8 px-8 md:px-16'>
//                   <div className='flex flex-col gap-2 md:gap-4'>
//                     <p className='text-3xl md:text-5xl font-medium'>Earn Certifications That Matter</p>
//                     <p className='text-xs md:text-sm opacity-50 text-justify'>Gain industry-recognized certifications upon completion, adding value to your resume and validating your expertise to potential employers.</p>
//                   </div>
//                   <div className='flex gap-2'>
//                     <Button className='rounded-[4px]' color='primary'> Contact us</Button>
//                     <Button className='rounded-[4px]' color='primary' variant='bordered'> Whatsapp</Button>
//                   </div>
//                 </div>
//                 <div className='h-[300px] md:h-full w-full md:w-fit md:mx-auto'>
//                   <img src='https://png.pngtree.com/png-vector/20230902/ourmid/pngtree-teenagers-distance-learning-online-education-3d-character-illustration-png-image_9239403.png' alt='' className='h-full w-full object-cover' />
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>

//           <SwiperSlide className='h-full w-full bg-transparent flex items-center justify-center pb-8'>
//             <div className='h-[92%] w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] '>
//               <div className='h-full w-full bg-white rounded-[8px] grid grid-cols-1 md:grid-cols-2 md:items-center md:justify-between'>
//                 <div className='h-[300px] md:h-fit flex flex-col gap-6 md:gap-8 py-8 px-8 md:px-16'>
//                   <div className='flex flex-col gap-2 md:gap-4'>
//                     <p className='text-3xl md:text-5xl font-medium'>Join a Community of Innovators</p>
//                     <p className='text-xs md:text-sm opacity-50 text-justify'>Become part of a vibrant learning community where you can connect, collaborate, and grow with peers and mentors across the tech industry.</p>
//                   </div>
//                   <div className='flex gap-2'>
//                     <Button className='rounded-[4px]' color='primary'> Contact us</Button>
//                     <Button className='rounded-[4px]' color='primary' variant='bordered'> Whatsapp</Button>
//                   </div>
//                 </div>
//                 <div className='h-[300px] md:h-full w-full md:w-fit md:mx-auto'>
//                   <img src='https://png.pngtree.com/png-vector/20230902/ourmid/pngtree-teenagers-distance-learning-online-education-3d-character-illustration-png-image_9239403.png' alt='' className='h-full w-full object-cover' />
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>

//         </Swiper>
//       </section>

//       <section className='h-fit w-full'>
//         <div className='h-fit w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] flex flex-col gap-8'>
//           <div className='flex flex-col gap-4'>
//             <p className='text-5xl font-black'>Upcomming Programs</p> 
//             <div className='border-l-[5px] border-blue-600 pl-2 py-1'>
//               <p className='text-base'>Stay Updated</p>  
//               <p className='text-sm opacity-50'>Explore our latest events, workshops, and webinars. Don’t miss out—join us and grow!</p>  
//             </div>  
//           </div>
//           <div className='h-fit w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 px-[10px] md:px-0'>
//             <div className='h-fit w-full rounded-[8px] overflow-hidden bg-white'>
//               <img src='https://decode.kz/images/blog/5f8efce9724615751d28fbf0.png' alt='' className='h-[150px] w-full object-cover' />
//               <div className='flex flex-col p-6 sm:p-4 gap-6'>
//                 <div className='h-fit flex flex-col gap-1'>
//                   <p className='text-xl sm:text-lg font-medium '>Course Name</p>
//                   <p className='text-sm opacity-50 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
//                 </div>
//                 <div className='flex items-center gap-2'>
//                   <p className='text-xs opacity-75'>Str - 24 Nov</p>
//                   <div className='h-[4px] min-h-[4px] w-[4px] min-w-[4px] bg-black rounded-full'>
//                     <div className='h-[4px] min-h-[4px] w-[4px] min-w-[4px] bg-black rounded-full'></div>
//                   </div>
//                   <p className='text-xs opacity-75'> 3 months</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className='h-fit w-full'>
//         <div className='h-fit w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px] flex flex-col gap-8'>
//           <div className='flex flex-col gap-4'>
//             <p className='text-5xl font-black'>Ongoing Programs</p> 
//             <div className='border-l-[5px] border-blue-600 pl-2 py-1'>
//               <p className='text-base'>Stay Updated</p>  
//               <p className='text-sm opacity-50'>Explore our current live courses and projects designed to boost skills and empower growth.</p>  
//             </div>  
//           </div>
//           <div className='h-fit w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 px-[10px] md:px-0'>
//             <div className='h-fit w-full rounded-[8px] overflow-hidden bg-white'>
//               <img src='https://decode.kz/images/blog/5f8efce9724615751d28fbf0.png' alt='' className='h-[150px] w-full object-cover' />
//               <div className='flex flex-col p-6 sm:p-4 gap-6'>
//                 <div className='h-fit flex flex-col gap-1'>
//                   <p className='text-xl sm:text-lg font-medium '>Course Name</p>
//                   <p className='text-sm opacity-50 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
//                 </div>
//                 <div className='flex items-center gap-2'>
//                   <p className='text-xs opacity-75'>Str - 24 Nov</p>
//                   <div className='h-[4px] min-h-[4px] w-[4px] min-w-[4px] bg-black rounded-full'>
//                     <div className='h-[4px] min-h-[4px] w-[4px] min-w-[4px] bg-black rounded-full'></div>
//                   </div>
//                   <p className='text-xs opacity-75'> 3 months</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//     </div>