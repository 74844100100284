import React from 'react'
import logo from '../assets/logo.png'
import { Button } from '@nextui-org/react'
import { Link, useLocation } from 'react-router-dom'
import { CiFacebook, CiInstagram, CiYoutube } from "react-icons/ci";

const Footer = () => {
  const location = useLocation()
  

  return (
    <footer class="py-12 bg-gray-800 text-gray-300">
            <div class="text-center">
              <img src="https://sdsverification.com/wp-content/uploads/2020/12/finance4-footer-bg1.png" alt="Footer Background" class="mx-auto"/>
              <p>@ SDSB Verification All Rights Reserved</p>
            </div>
          </footer>
  )
}

export default Footer


// <div className={
//       location.pathname.startsWith('/user')?
//       'h-fit w-full py-16 bg-white hidden':
//       'h-fit w-full flex py-16 bg-white'
//     }
//     >
//       <scetion className='h-fit w-full max-w-[1024px] xl:max-w-[1200px] mx-auto px-[15px] sm:px-[25px]'>
//         <div className='h-fit w-full grid grid-cols-1 md:grid-cols-2'>
//             <div className='w-full flex flex-col gap-6'>
//                 <Link to='/' className='flex items-center justify-start gap-2'>
//                   <img src={logo} alt='' className='h-[150px] w-[150px] object-contain -ml-[5px]' />
//                   <p className='text-3xl text-blue-600 font-black uppercase leading-10'>Skills Sphere <br/> Solutions</p>
//                 </Link>
//                 <p className='opacity-50 max-w-[500px] text-sm text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
//                 <div className='flex flex-row gap-4'>
//                   <div className='h-[50px] w-[50px] rounded-full border-1 border-black/15 flex items-center justify-center '>
//                     <CiInstagram className='text-2xl' />
//                   </div>
//                   <div className='h-[50px] w-[50px] rounded-full border-1 border-black/15 flex items-center justify-center '>
//                     <CiFacebook  className='text-2xl'/>
//                   </div>
//                   <div className='h-[50px] w-[50px] rounded-full border-1 border-black/15 flex items-center justify-center '>
//                     <CiYoutube  className='text-2xl'/>
//                   </div>
//                 </div>
//             </div>
//             <div className='w-full text-sm  grid grid-cols-2 gap-16 px-[25px]'>

//               <div className='text-base flex flex-col gap-4'>
//                 <p className='font-bold text-lg'>Company</p>
//                 <div className='opacity-75 flex flex-col gap-2'>
//                   <p className=''>About us</p>
//                   <p className=''>Blog</p>
//                   <p className=''>Contact Us</p>
//                   <p className=''>Pricing</p>
//                   <p className=''>Testimonials</p>
//                 </div>
//               </div>

//               <div className='text-base flex flex-col gap-4'>
//                 <p className='font-bold text-lg'>Company</p>
//                 <div className='opacity-75 flex flex-col gap-2'>
//                   <p className=''>About us</p>
//                   <p className=''>Blog</p>
//                   <p className=''>Contact Us</p>
//                   <p className=''>Pricing</p>
//                   <p className=''>Testimonials</p>
//                 </div>
//               </div>

//             </div>
//         </div>
//       </scetion>
//     </div>